import { render, staticRenderFns } from "./TreeViewEditablePreview.vue?vue&type=template&id=20858c20&scoped=true&"
import script from "./TreeViewEditablePreview.vue?vue&type=script&lang=js&"
export * from "./TreeViewEditablePreview.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20858c20",
  null
  
)

export default component.exports